html,
body {
 	height: 100%;
	min-height: 100%;
}

body {
	overflow-y: scroll;
	overflow-x: hidden;

	@include breakpoint(small only) {
		font-size: 15px;
	}
}

body.tipps {
	.content {
		background-image: url(../img/hinter_tipps.jpg);
		background-repeat: no-repeat;
	}
}

.wrapper {
	width: 100%;
	max-width: 975px;
	overflow: hidden;
	@include push-center;
}

.header img {
	@include breakpoint(small only) {
		width: 100%;
	}
}


.btn-offCanvas {
	display: none;
	line-height: 62px;
	text-align: center;
	border-top: 10px solid $white;

	a {
		font-size: 0;
		@include hamburger($black, $black, 30px, 16px, 1px, 3);
	}

	@include breakpoint(small only) {
		display: block;
	}
}

.off-canvas {
	padding-top: 1rem;

	.close-button {
		font-size: 2.5rem;
		float: right;
	}

	ul.menu {
		float: right;

		li {
			display: block;
			width: 100%;

			ul {
				margin-left: 0;

				a {
					font-weight: normal;
				}
			}
		}

		a {
			padding: 0;
			font-weight: bold;
			text-decoration: none;
			padding-left: 0;
			padding-top: 15px;
			padding-bottom: 15px;
			display: block;
			border-bottom: 1px solid #fff;
			margin: 1px 0px;

			&.active,
			&:hover {
				color: #003399;
				background-color: #cce3cc;
			}
		}
	}
}

.image-box {
	padding-left: 0;

	@include breakpoint(small only) {
		padding-left: 0.625rem;
	}
}

.content {
	ul li {
		margin-left: 1.3rem;
		margin-bottom: 1rem;
		text-align: left;
	}

	@include breakpoint(small only) {
		text-align: left;
	}
}