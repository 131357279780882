body { background-image: url(../img/hinter_gr.jpg);
	background-repeat: repeat-x; margin-top: 0px;
	margin-right: 0px; margin-bottom: 0px; margin-left: 0px;
	font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 10pt;
	color: #004494;
}

.hinter { background-image: url(../img/hinter_kl.jpg);
	background-repeat: repeat-x;
}

.menue { background-image: url(../img/m.jpg);
	background-repeat: repeat-x;
	padding-top: 23px;
}

.content { background-color: #ffffff; color: #004494;
	padding-top: 23px; padding-bottom: 15px;
}

.content_tipps { background-color: #ffffff; background-image: url(../img/hinter_tipps.jpg);
	background-repeat: no-repeat; font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 10pt; color: #004494;
	padding-top: 23px; padding-right: 15px; padding-bottom: 15px; padding-left: 15px;
}

a { color: #003399; text-decoration: underline; font-weight: normal; }

a:hover { color: #b5d5b5; text-decoration: none; font-weight: normal; }

a.men {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 10pt;
	color: #003399;
	font-weight: bold;
	text-decoration: none;
	padding-left: 0;
	padding-top: 3px;
	padding-bottom: 3px;
	display: block;
	width: 177px;
	border-bottom: 1px solid #fff;
	margin: 1px 0px;
	text-align: left;
}

a.men:hover { color: #003399; background-color: #cce3cc; font-weight: bold; text-decoration: none; border-bottom: 1px solid #fff;
}

a.aktive {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 10pt;
	color: #003399;
	font-weight: bold;
	text-decoration: none;
	background-color: #cce3cc;
	padding-left: 0;
	padding-top: 3px;
	padding-bottom: 3px;
	display: block;
	width: 177px;
	border-bottom: 1px solid #fff;
	margin: 1px 0px;
	text-align: left;
}

a.aktive:hover { color: #003399; background-color: #cce3cc; font-weight: bold; text-decoration: none;
}

a.menunter {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 8pt;
	color: #003399;
	font-weight: normal;
	text-decoration: none;
	padding-left: 0;
	padding-top: 5px;
	padding-bottom: 5px;
	display: block;
	width: 177px;
	margin: 1px 0px;
	text-align: left;
}

a.menunter:hover { color: #003399; background-color: #dcf0dc; font-weight: normal; text-decoration: none;
}

a.unteraktive {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 8pt;
	color: #003399;
	font-weight: normal;
	text-decoration: none;
	background-color: #dcf0dc;
	padding-left: 0;
	padding-top: 5px;
	padding-bottom: 5px;
	display: block;
	width: 177px;
	margin: 1px 0px;
	text-align: left;
}

a.unteraktive:hover { color: #003399; background-color: #dcf0dc; font-weight: normal; text-decoration: none;
}