.main-nav {
	padding: 0;
	background-image: url(../img/m.jpg);
	background-repeat: repeat-x;
	padding-top: 23px;

	ul.menu {
		li {
			display: block;
			width: 100%;

			ul {
				margin-left: 0;

				a {
					font-weight: normal;
				}
			}
		}

		a {
			padding: 0;
			font-weight: bold;
			text-decoration: none;
			padding-left: 0;
			padding-top: 5px;
			padding-bottom: 5px;
			display: block;
			border-bottom: 1px solid #fff;
			margin: 1px 0px;

			&.active,
			&:hover {
				color: #003399;
				background-color: #cce3cc;
			}
		}
	}

	@include breakpoint(small only) {
		display: none;
	}
}